import React from "react";
import CustomTranslation from "../../Components/CustomTranslation";
import G from "../../../Actions/GeneralAction";
import Config from "../../../Config.json";
import fileDownload from "js-file-download";
import CustomConfirm from "../../Components/CustomConfirm";
import VGPlanAction from "../../../Actions/VGPlanAction";
import {toast} from "react-hot-toast";

//define class
class VGPlanDocument extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //state
        this.state = {
            active: true,
            showDelete: false
        }
    }

    //download document
    downloadFile = async (url, ext) => {
        //default file name
        let fileName = 'vgm_plan.' + ext;
        if (this.props.data.originalFileName) {
            //fileName = this.props.data.originalFileName;
        }
        //get mimetype
        let mimetype = 'plain/text';
        switch (ext) {
            case 'pdf':
                mimetype = 'application/pdf';
                break;
            case 'docx':
                mimetype = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                break;
        }
        //fetch
        fetch(url)
            .then(res => {
                return res.blob();
            })
            .then(res => {
                fileDownload(res, fileName, mimetype);
            });
    }

    //send delete call
    deletePlan = async () => {

        //make call
        let result = await VGPlanAction.removeUpload(this.props.vgPlan.id, this.props.data.id);

        //Show toast
        toast.success(<CustomTranslation value={'KEY.V&G plan removed!'} />);

        //hide
        this.setState({
            active: false
        });
    }

    //render view
    render() {

        return (
            this.state.active ?
                <div className={this.props.className} style={this.props.style}>
                    <div
                        className={'bordered rounded standard-padding vertical-padding touchable'}
                        style={{
                            display: 'flex',
                            flexDirection: 'row'
                        }}
                        onClick={(event) => {
                            if (this.props.uploaded) {
                                this.downloadFile(this.props.data.path, 'pdf');
                            } else {
                                this.downloadFile(this.props.data.path, 'docx');
                            }
                        }}
                    >
                        <div
                            className={'icon-container dark-bg'}
                        >
                            <i className={'text-white icon ' + (this.props.uploaded ? 'las la-file-pdf' : 'las la-file-word')}/>
                        </div>
                        <div
                            style={{
                                flexGrow: 1,
                                flexShrink: 1
                            }}
                            className={'standard-padding-2'}
                        >
                            <h5 className={'unbold'}>
                                <CustomTranslation value={'KEY.Download'}/>
                            </h5>
                            <h3 style={{wordBreak: 'break-word'}}>
                                {this.props.uploaded ?
                                    (this.props.data.originalFileName)
                                    :
                                    (
                                        (this.props.data.createdBy.firstName || this.props.data.createdBy.lastName) ?
                                            (this.props.data.createdBy.firstName || '') + ' ' + (this.props.data.createdBy.lastName || '')
                                            :
                                            this.props.data.createdBy.email
                                    )
                                }
                            </h3>
                            <h5 className={'unbold'}>
                                [{G.formatDateTime(this.props.data.createdAt)}]
                            </h5>
                        </div>
                        {this.props.uploaded &&
                        <div>
                            <div
                                className={'icon-btn-container bg-danger touchable'}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    this.setState({showDelete: true});
                                }}
                            >
                                <i className={'icon-btn las la-trash text-white'}/>
                            </div>
                        </div>
                        }
                    </div>

                    {/* Confirm modal */}
                    <CustomConfirm
                        show={this.state.showDelete}
                        title={<CustomTranslation value={'KEY.Remove'}/>}
                        variant={'danger'}
                        body={
                            <p>
                                <CustomTranslation value={'KEY.Do you wish to delete the VG plan:'}/> "{this.props.data.originalFileName}"?
                            </p>
                        }
                        cancelLabel={<CustomTranslation value={'KEY.Cancel'}/>}
                        onCancel={() => {
                            this.setState({showDelete: false});
                        }}
                        confirmLabel={<CustomTranslation value={'KEY.Remove'}/>}
                        onConfirm={() => {
                            this.deletePlan();
                        }}
                    />
                </div>
            :
                null
        );
    }
}

//export
export default VGPlanDocument;
