import React from "react";
import UserActions from "../../Actions/UserAction";
import ConstructionSiteAction from "../../Actions/ConstructionSiteAction";
import AssignmentAction from "../../Actions/AssignmentAction";
import CustomContainer from "../Components/CustomContainer";
import ConstructionSiteWrapper from "../Components/ConstructionSiteWrapper";
import Project from "../Home/Components/Project";
import { Breadcrumb, Button, Image } from "react-bootstrap";
import { generatePath, useParams } from "react-router-dom";
import CustomTranslation from "../Components/CustomTranslation";
import Loader from "../Components/Loader";
import GeneralAction from "../../Actions/GeneralAction";
import TranslationAction from "../../Actions/TranslationAction";
import LanguageRepository from "../../Database/Repositories/LanguageRepository";
import APIAction from "../../Actions/APIAction";
import CustomInput from "../Components/CustomInput";
import CustomCheckBox from "../Components/CustomCheckBox";
import CustomSelect from "../Components/CustomSelect";
import RiskAnalysisFileButton from "../RiskAnalysis/Components/RiskAnalysisFileButton";
import Config from "../../Config.json";
import fileDownload from "js-file-download";
import CustomImageInput from "../Components/CustomImageInput";
import FileButton from "../ConstructionSite/Components/FileButton";
import UserAction from "../../Actions/UserAction";
import LicenseAction from "../../Actions/LicenseAction";
import ToolboxAction from "../../Actions/ToolboxAction";
import CustomListInput from "../Components/CustomListInput";

class AssignmentItemScreen extends React.Component {

    //constructor
    constructor(props) {
        super(props);

        //set state
        this.state = {

        };
    }

    componentDidMount = () => {
        this.updateElements();
    }

    updateElements = async () => {
        //get construction site
        let cs = await ConstructionSiteAction.getConstructionSite(this.props.params.construction_site_id);

        //rights
        let userConstructionSiteRights = await UserAction.getUserRightByConstructionSiteId(cs.id);
        let userCompanyRights = await UserActions.getUserRightByCompanyId(cs.company ? cs.company.id : null);
        if (!userConstructionSiteRights && userCompanyRights?.adminRights) {
            userConstructionSiteRights = { managementRights: true };
        }

        //licenses
        let licenses = await LicenseAction.getLicensesByUserAndCompany(cs.company ? cs.company.id : null);

        //get assignment
        let assignment = await AssignmentAction.get(this.props.params.assignment_id);

        //check if photo is available
        let photoState = {};
        if (assignment.followUpPhoto) {
            photoState['imageId'] = assignment.followUpPhoto.id;
            photoState['imageApiId'] = assignment.followUpPhoto['@id'];
        }

        if (assignment.followUpAltPhoto) {
            photoState['image2Id'] = assignment.followUpAltPhoto.id;
            photoState['image2ApiId'] = assignment.followUpAltPhoto['@id'];
        }

        if (assignment.toolbox && assignment.altPhoto) {
            photoState['image3Id'] = assignment.altPhoto.id;
            photoState['image3ApiId'] = assignment.altPhoto['@id'];
        }

        //get current user
        let currentUser = await UserActions.getCurrentUser();

        //get users for construction site
        let users = await UserActions.getUserListConstructionSite(cs.id);

        //define user options
        let userOptions = [];

        //loop
        users.forEach(element => {
            let label = null;
            if ((!element.firstName) || (!element.lastName)) {
                label = element.email;
            } else {
                label = element.firstName + ' ' + element.lastName;
            }
            userOptions.push({
                label: label,
                value: element.id
            });
        });

        //remove current user
        let userFound = null;
        if (assignment.targetUser) {
            userOptions.forEach((element, index) => {
                if (element.value === assignment.targetUser.id) {
                    userFound = element;
                    delete userOptions[index];
                }
            });
        }

        //if user found is not null
        if (userFound) {
            //default value
            let defaultValue = { label: userFound.label, value: userFound.value };
            userOptions = [defaultValue].concat(userOptions);
        }

        let selectedUser = userOptions[0];

        //if toolbox, get participants
        let participantsState = {};
        if (assignment.toolbox) {
            participantsState['participants'] = assignment.toolbox.participants.map((participant) => participant.name);
        }

        //set in state
        this.setState({
            data: {
                constructionSite: cs,
                assignment: assignment
            },
            comment: assignment.comment,
            finished: false,
            currentUser: currentUser,
            userOptions: userOptions,
            selectedUser: selectedUser,
            forwardUser: { id: assignment.targetUser.id },
            licenses: licenses,
            userConstructionSiteRights: userConstructionSiteRights,
            ...photoState,
            ...participantsState
        });
    }

    //data from image upload
    handleUpload = async (file, name) => {
        let newState = {};
        newState[`${name}Id`] = file.id;
        newState[`${name}ApiId`] = file['@id'];
        this.setState(newState);
    }

    //save data
    saveData = async (navigate = true) => {
        let response = null;
        if (this.state.data.assignment.toolbox) {
            response = await ToolboxAction.updateToolbox({ id: this.state.data.assignment.toolbox.id, participants: this.state.participants.map(participant => { return { name: participant, signature: null }; }) });
        }

        let toolBoxData = {};
        if (this.state.image3Id) {
            toolBoxData['image3Id'] = this.state.image3Id;
        }

        await AssignmentAction.save({ ...this.state.data.assignment, ...{ comment: this.state.comment, finished: this.state.finished, forwardUser: this.state.forwardUser, imageId: this.state.imageId, image2Id: this.state.image2Id }, ...toolBoxData });
        if (navigate) {
            window.location.href = generatePath('/construction_site/:construction_site_id/assignments', { construction_site_id: this.state.data.constructionSite.id });
        }
        return response;
    }

    render = () => {
        if (this.state && this.state.data) {
            //set status
            this.status = '';
            if (this.state.data.assignment.dateFinished) {
                this.status = ' bg-success';
            } else {
                this.status = ' bg-primary';
            }

            //set assignment
            this.assignment = this.state.data.assignment;

            //is positive
            let positive = false;
            if (this.assignment.observation && this.assignment.observation.positiveRemark) positive = true;
            if (this.assignment.inspectionChecklistItem && this.assignment.inspectionChecklistItem.positiveRemark) positive = true;


            //return
            return (
                <CustomContainer
                    footer={
                        !this.assignment.dateFinished &&
                        <Button
                            block
                            variant={'dark'}
                            disabled={this.assignment.toolbox && false ?
                                (!this.state.participants || this.state.participants.length === 0 || (this.assignment.toolbox.document === null && this.state.image3Id === null)) :
                                false
                            }
                            onClick={() => {
                                this.saveData(true);
                            }}
                        >
                            <CustomTranslation value={'KEY.Save action'} />
                        </Button>
                    }
                >
                    <ConstructionSiteWrapper
                        constructionSite={this.state.data.constructionSite}
                    >
                        <div>
                            {/* Project header */}
                            <div style={{ position: 'sticky', top: '2rem', zIndex: 9 }}>
                                <Project
                                    float
                                    data={this.state.data.constructionSite}
                                />
                            </div>
                            {/* Breadcrumb */}
                            <Breadcrumb className={'vertical-margin-2'}>
                                <Breadcrumb.Item
                                    href={generatePath('/')}
                                >
                                    <CustomTranslation value={'KEY.Home'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:id', { id: this.state.data.constructionSite.id })}
                                >
                                    {this.state.data.constructionSite.name}
                                </Breadcrumb.Item>
                                <Breadcrumb.Item
                                    href={generatePath('/construction_site/:construction_site_id/assignments', { construction_site_id: this.state.data.constructionSite.id })}
                                >
                                    <CustomTranslation value={'KEY.Assignment actions'} />
                                </Breadcrumb.Item>
                                <Breadcrumb.Item active>
                                    <CustomTranslation value={'KEY.Assignment'} />
                                </Breadcrumb.Item>
                            </Breadcrumb>

                            {/* Assignment */}
                            <div
                                className={'bordered rounded'}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row'
                                    }}
                                >
                                    <div
                                        className={'rounded' + this.status}
                                        style={{
                                            minWidth: '10px',
                                            maxWidth: '10px'
                                        }}
                                    />
                                    <div
                                        style={{
                                            flexGrow: 1,
                                            flexShrink: 1
                                        }}
                                    >
                                        <p
                                            className={'label bg-light'}
                                            style={{ padding: '0.5rem' }}
                                        >
                                            {this.assignment.message && !(this.assignment.accessibilityOverview) &&
                                                <CustomTranslation value={'KEY.Risk analysis message'} />
                                            }
                                            {this.assignment.message && this.assignment.accessibilityOverview &&
                                                <CustomTranslation value={'KEY.Accessibility message'} />
                                            }
                                            {this.assignment.lastMinuteRiskAnalysisItem &&
                                                <CustomTranslation value={'KEY.LMRA'} />
                                            }
                                            {this.assignment.observation && !(this.assignment.observation.positiveRemark) &&
                                                <CustomTranslation value={'KEY.Observation'} />
                                            }
                                            {this.assignment.observation && this.assignment.observation.positiveRemark &&
                                                <CustomTranslation value={'KEY.Positive Remark'} />
                                            }
                                            {this.assignment.taskRiskAnalysisItem &&
                                                <CustomTranslation value={'KEY.Risk analysis item'} />
                                            }
                                            {this.assignment.inspectionChecklistItem && !(this.assignment.inspectionChecklistItem.positiveRemark) &&
                                                <CustomTranslation value={'KEY.Inspection checklist item'} />
                                            }
                                            {this.assignment.inspectionChecklistItem && this.assignment.inspectionChecklistItem.positiveRemark &&
                                                <CustomTranslation value={'KEY.Positive inspection checklist item'} />
                                            }
                                            {this.assignment.toolbox &&
                                                <CustomTranslation value={'KEY.Toolbox'} />
                                            }
                                        </p>
                                        {this.assignment.message &&
                                            <div
                                                className={'bg-light'}
                                                style={{
                                                    padding: '0.5rem',
                                                    marginTop: '-1rem'
                                                }}
                                            >
                                                <h4
                                                    style={{ marginBottom: 0 }}
                                                >
                                                    {this.assignment.title}
                                                </h4>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {/* Content */}
                                <div
                                    className={'standard-padding-2 vertical-padding-2'}
                                >
                                    {this.assignment.photo &&
                                        <div style={{ paddingBottom: 5 }}>
                                            <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation value={'KEY.Image overview'} /></p>
                                            <Image height={300} style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.assignment.photo.resizedPath} rounded />
                                        </div>
                                    }

                                    {this.assignment.altPhoto && !this.assignment.toolbox &&
                                        <div style={{ paddingBottom: 5 }}>
                                            {this.assignment.toolbox ?
                                                <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation value={'KEY.Instruction/Toolboxmoment'} /></p> :
                                                <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation value={'KEY.Image detail'} /></p>
                                            }
                                            <Image height={300} style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.assignment.altPhoto.resizedPath} rounded />
                                        </div>
                                    }

                                    <div
                                        className={'border-bottom vertical-margin-3'}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation value={'KEY.From'} /></p>
                                        <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                            {(this.assignment.sender.firstName || this.assignment.sender.lastName)
                                                ?
                                                (this.assignment.sender.firstName || '') + ' ' + (this.assignment.sender.lastName || '')
                                                :
                                                (this.assignment.sender.email || '')
                                            }
                                        </p>
                                    </div>
                                    <div
                                        className={'border-bottom vertical-margin-3'}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation value={'KEY.To'} /></p>
                                        <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                            {(this.assignment.targetUser.firstName || this.assignment.targetUser.lastName)
                                                ?
                                                (this.assignment.targetUser.firstName || '') + ' ' + (this.assignment.targetUser.lastName || '')
                                                :
                                                (this.assignment.targetUser.email || '')
                                            }
                                        </p>
                                    </div>
                                    <div
                                        className={'border-bottom vertical-margin-3'}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation value={'KEY.Date'} /></p>
                                        <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                            {GeneralAction.formatDate(this.assignment.createdAt)}
                                        </p>
                                    </div>
                                    {!this.assignment.message &&
                                        <div>
                                            <div
                                                className={'border-bottom vertical-margin-3'}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation
                                                    value={'KEY.Title'} /></p>
                                                <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                                    {this.assignment.title}
                                                </p>
                                            </div>
                                            <div
                                                className={'border-bottom vertical-margin-3'}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <p className={'label'} style={{ paddingBottom: 0 }}>
                                                    {this.assignment.toolbox ?
                                                        <CustomTranslation value={'KEY.Toolbox ready by'} /> :
                                                        <CustomTranslation value={'KEY.Solve by'} />
                                                    }
                                                </p>
                                                <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                                    {GeneralAction.formatDate(this.assignment.dateRequested)}
                                                </p>
                                            </div>
                                            {!this.assignment.toolbox &&
                                                <div
                                                    className={'border-bottom vertical-margin-3'}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <p className={'label'} style={{ paddingBottom: 0 }}>
                                                        {
                                                            positive ?
                                                                <CustomTranslation value={'KEY.Positive find'} />
                                                                :
                                                                <CustomTranslation value={'KEY.Description of risk'} />
                                                        }
                                                    </p>
                                                    <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                                        {this.assignment.riskDescription}
                                                    </p>
                                                </div>
                                            }
                                            {!(positive) &&
                                                <div
                                                    className={'border-bottom vertical-margin-3'}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <p className={'label'} style={{ paddingBottom: 0 }}>
                                                        {this.assignment.toolbox ?
                                                            <CustomTranslation value={'KEY.Optional extra information'} /> :
                                                            <CustomTranslation value={'KEY.Action taken'} />
                                                        }
                                                    </p>
                                                    <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                                        {this.assignment.immediateActionTaken ? this.assignment.immediateActionTaken.split('\n').map((item, key) => {
                                                            return <span key={key}>{item}<br /></span>;
                                                        }) : <CustomTranslation value={'KEY.None'} />}
                                                    </p>
                                                </div>
                                            }
                                            {!this.assignment.toolbox &&
                                                <div
                                                    className={'border-bottom vertical-margin-3'}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <p className={'label'} style={{ paddingBottom: 0 }}>
                                                        {positive ?
                                                            <CustomTranslation value={'KEY.Possible Recommendation'} />
                                                            :
                                                            <CustomTranslation value={'KEY.Recommendation'} />
                                                        }
                                                    </p>
                                                    <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                                        {this.assignment.recommendation ? this.assignment.recommendation.split('\n').map((item, key) => {
                                                            return <span key={key}>{item}<br /></span>;
                                                        }): <CustomTranslation value={'KEY.None'} />}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    }
                                    {this.assignment.message &&
                                        <div>
                                            <div
                                                className={'border-bottom vertical-margin-3'}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <p className={'label'} style={{ paddingBottom: 0 }}>
                                                    <CustomTranslation value={'KEY.Title'} />
                                                </p>
                                                <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                                    {this.assignment.message.title}
                                                </p>
                                            </div>
                                            <div
                                                className={'border-bottom vertical-margin-3'}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <p className={'label'} style={{ paddingBottom: 0 }}>
                                                    <CustomTranslation value={'KEY.Message'} />
                                                </p>
                                                <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                                    {this.assignment.message.body.split('\n').map((item, key) => {
                                                        return <span key={key}>{item}<br /></span>;
                                                    })}
                                                </p>
                                            </div>
                                        </div>
                                    }
                                    {this.assignment.dateFinished &&
                                        <div>
                                            <div
                                                className={'border-bottom vertical-margin-3'}
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}
                                            >
                                                <p className={'label'} style={{ paddingBottom: 0 }}>
                                                    <CustomTranslation value={'KEY.Finished on'} />
                                                </p>
                                                <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                                    {GeneralAction.formatDate(this.assignment.dateFinished)}
                                                </p>
                                            </div>
                                            {this.assignment.comment &&
                                                <div
                                                    className={'border-bottom vertical-margin-3'}
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        justifyContent: 'space-between'
                                                    }}
                                                >
                                                    <p className={'label'} style={{ paddingBottom: 0 }}>
                                                        <CustomTranslation value={'KEY.Follow-up'} />
                                                    </p>
                                                    <p style={{ marginBottom: 0, marginLeft: 5 }}>
                                                        {this.assignment.comment.split('\n').map((item, key) => {
                                                            return <span key={key}>{item}<br /></span>;
                                                        })}
                                                    </p>
                                                </div>
                                            }

                                            {this.assignment.followUpPhoto &&
                                                <div style={{ paddingBottom: 5 }}>
                                                    <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation value={'KEY.Image overview follow-up'} /></p>
                                                    <Image height={300} style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.assignment.followUpPhoto.resizedPath} rounded />
                                                </div>
                                            }

                                            {this.assignment.followUpAltPhoto &&
                                                <div style={{ paddingBottom: 5 }}>
                                                    <p className={'label'} style={{ paddingBottom: 0 }}><CustomTranslation value={'KEY.Image detail follow-up'} /></p>
                                                    <Image height={300} style={{ maxWidth: '100%', maxHeight: '30rem' }} src={this.assignment.followUpAltPhoto.resizedPath} rounded />
                                                </div>
                                            }
                                        </div>
                                    }
                                    {(!this.assignment.dateFinished) && (this.state.currentUser.id === this.assignment.targetUser.id || this.state.currentUser.id === this.assignment.sender.id) &&
                                        <div>
                                            {this.assignment.toolbox &&
                                                <div className={'vertical-padding-3'}>
                                                    <CustomImageInput
                                                        label={<CustomTranslation value={'KEY.Instruction/Toolboxmoment'} />}
                                                        labelAppend={this.assignment.toolbox.document ? '' : ''}
                                                        onUpload={(val) => this.handleUpload(val, 'image3')}
                                                        imageId={this.state.image3Id ? this.state.image3Id : null}
                                                    />
                                                </div>
                                            }
                                            {!this.assignment.toolbox &&
                                                <>
                                                    <div className={'vertical-padding-3'}>
                                                        <CustomImageInput
                                                            label={<CustomTranslation value={'KEY.Image overview follow-up'} />}
                                                            onUpload={(val) => this.handleUpload(val, 'image')}
                                                            imageId={this.state.imageId ? this.state.imageId : null}
                                                        />
                                                    </div>
                                                    <div className={'vertical-padding-3'}>
                                                        <CustomImageInput
                                                            label={<CustomTranslation value={'KEY.Image detail follow-up'} />}
                                                            onUpload={(val) => this.handleUpload(val, 'image2')}
                                                            imageId={this.state.image2Id ? this.state.image2Id : null}
                                                        />
                                                    </div>
                                                </>
                                            }
                                            <div className={'vertical-padding-3'}>
                                                <CustomInput
                                                    iconName={'las la-quote-left'}
                                                    label={<CustomTranslation value={'KEY.Follow-up'} />}
                                                    inputProps={{
                                                        as: 'textarea'
                                                    }}
                                                    value={this.state.comment}
                                                    onChangeText={(text) => {
                                                        this.setState({ comment: text });
                                                    }}
                                                />
                                            </div>
                                            <div className={'vertical-padding-3'}>
                                                <CustomCheckBox
                                                    label={<CustomTranslation value={'KEY.Finished'} />}
                                                    value={this.state.finished}
                                                    onValueChange={(value) => {
                                                        this.setState({
                                                            finished: value
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {!this.assignment.dateFinished ?
                                        <div className={'vertical-padding-3'}>
                                            <CustomSelect
                                                iconName={'las la-user'}
                                                label={<CustomTranslation value={'KEY.Forward to'} />}
                                                options={this.state.userOptions}
                                                selected={this.state.selectedUser}
                                                onSelect={(value, label) => {
                                                    this.setState({ forwardUser: { id: value } });
                                                    this.setState({ selectedUser: { label: label, value: value } });
                                                }}
                                            />
                                        </div>
                                        :
                                        null
                                    }

                                    {this.assignment.toolbox && (this.assignment.dateFinished === null) &&
                                        <div className={'vertical-padding-3'}>
                                            <CustomListInput
                                                label={<CustomTranslation value={'KEY.Participants'} />}
                                                labelAppend={'*'}
                                                unique={true}
                                                iconName={'las la-users'}
                                                onChangeText={(value) => {
                                                    let output = value;
                                                    if (typeof value === 'object') {
                                                        output = output.filter(n => {
                                                            return n !== '';
                                                        });
                                                    }
                                                    this.setState({ participants: output });
                                                }}
                                                value={this.state.participants}
                                            />
                                        </div>
                                    }

                                </div>
                            </div>

                            {/* Download file */}
                            {this.assignment.message && (!this.assignment.accessibilityOverview) &&
                                <div className={'vertical-margin-2'}>
                                    <RiskAnalysisFileButton
                                        title={<CustomTranslation value={this.assignment.message.taskRiskAnalysis.title} />}
                                        onClick={(event) => {
                                            //default file name
                                            let fileName = 'document.pdf';
                                            fetch(Config.BASE_URL + '/en/general/document/tra/full/' + this.assignment.message.taskRiskAnalysis.id + '/1')
                                                .then(res => {
                                                    fileName = res.headers.get('content-disposition');
                                                    let parts = fileName.split(';');
                                                    fileName = parts[1].split('=')[1];
                                                    return res.blob();
                                                })
                                                .then(res => {
                                                    fileName = fileName.replace(/['"]+/g, '');
                                                    fileDownload(res, fileName, 'application/pdf');
                                                });
                                        }}
                                    />
                                </div>
                            }
                            {this.assignment.message && this.assignment.accessibilityOverview &&
                                <div className={'vertical-margin-2'}>
                                    <RiskAnalysisFileButton
                                        title={<CustomTranslation value={'KEY.Accessibility Overview'} />}
                                        onClick={async (event) => {
                                            //default file name
                                            let now = new Date();
                                            let fileName = await TranslationAction.translate('KEY.Accessibility Overview');
                                            fileName += ' ' + now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
                                            fileName += '.pdf';

                                            //get language
                                            let languageRepository = new LanguageRepository();
                                            let language = await languageRepository.getSelected();

                                            //request
                                            APIAction.request('/construction_sites/' + this.assignment.constructionSiteId + '/pdf', 'GET', true, { language: language.code }, true, false, 'json', true)
                                                .then(result => {
                                                    fileName = fileName.replace(/['"]+/g, '');
                                                    fileDownload(result, fileName, 'application/pdf');
                                                });
                                        }}
                                    />
                                </div>
                            }

                            {this.assignment.toolbox && this.state.userConstructionSiteRights?.managementRights && (this.state.licenses && this.state.licenses.length > 0) &&
                                <FileButton
                                    style={{ marginTop: 10 }}
                                    topTitle={<CustomTranslation value={'KEY.Sign'} />}
                                    title={<CustomTranslation value={'KEY.Toolbox'} />}
                                    iconName={'las la-file-alt'}
                                    onClick={async () => {
                                        let toolbox = await this.saveData(false);
                                        window.location.href = generatePath('/construction_site/:construction_site_id/toolbox/:toolbox_id/:return_assignment_id', { construction_site_id: this.state.data.constructionSite.id, toolbox_id: this.assignment.toolbox.id, return_assignment_id: this.assignment.id });
                                    }}
                                />
                            }

                            {this.assignment.toolbox && this.assignment.toolbox.document &&
                                <div>
                                    <FileButton
                                        style={{ marginTop: 10 }}
                                        topTitle={<CustomTranslation value={'KEY.Download'} />}
                                        title={<CustomTranslation value={'KEY.Document detail'} />}
                                        iconName={'las la-file'}
                                        onClick={async () => {
                                            if (!this.state.toolBoxDocWaiting) {
                                                //set waiting
                                                this.setState({ toolBoxDocWaiting: true });

                                                //create file name
                                                let now = new Date();
                                                let fileName = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate() + '-' + this.assignment.toolbox.document.originalFileName;

                                                let file = await fetch(this.assignment.toolbox.document.path, {
                                                    method: 'GET',
                                                });
                                                file = await file.blob();

                                                this.setState({ toolBoxDocWaiting: false });
                                                fileDownload(file, fileName);
                                            }
                                        }}
                                    />
                                </div>
                            }
                        </div>
                    </ConstructionSiteWrapper>
                </CustomContainer>
            );
        } else {
            return (
                <CustomContainer>
                    <Loader />
                </CustomContainer>
            );
        }
    }
}

//export
export default function (props) {
    const params = useParams();
    return <AssignmentItemScreen {...props} params={params} />;
}
